export default function IconWithLabel({icon, label, className = ''}) {
    return (
        <div className={`w-full text-center max-w-[200px] flex flex-col items-center gap-8 md:gap-8 mx-auto ${className}`}>
            <p className="text-4xl">
                {icon}
            </p>
            <p className="text-sm md:text-lg leading-[1.4] md:leading-[1.4]"
               dangerouslySetInnerHTML={{__html: label}}
            />
        </div>
    )
}