import localized from "../locale/locales";

import MainLayout from "../components/layouts/MainLayout";
import Button from "../components/Button";
import Hero from "../components/Hero";
import SectionVideo from "../components/sections/SectionVideo";
import SectionFloating from "../components/sections/SectionFloating";
import SectionFeatures from "../components/sections/SectionFeatures";
import SectionTiles from "../components/sections/SectionTiles";
import SectionIconsWithLabels from "../components/sections/SectionIconsWithLabels";
import SectionTestimonials from "../components/sections/SectionTestimonials";
import SectionInstagram from "../components/sections/SectionInstagram";

import imageHeroDesktop from '../public/img/hero/homepage_desktop.jpg';
import imageHeroMobile from '../public/img/hero/homepage_mobile.jpg';
import Head from "next/head";
import {generateTitle} from "../helpers/meta";
import {useRouter} from "next/router";
import Title from "../components/Title";

export default function Home() {
    const {locale} = useRouter();
    const {homepage: lang} = localized[locale];

    return (
        <MainLayout>
            <Head>
                <title key="title">{generateTitle(lang.og.title)}</title>
                <meta key="og:title" property="og:title" content={generateTitle(lang.og.title)}/>
            </Head>

            <SectionFloating scheme="home"/>

            <Hero direction="image-right"
                  imageDesktop={imageHeroDesktop}
                  imageMobile={imageHeroMobile}
                  className="relative items-end gap-6 lg:gap-10 lg:pt-5 accent-purple-800"
            >
                <div className="flex flex-col gap-7 md:gap-10 md:pb-10">
                    <div className="flex flex-col gap-5 md:gap-6">
                        <header>
                            <Title Elem="h1">
                                {lang.header.title.localize({emoji: '💰'})}
                            </Title>
                        </header>
                        <p className="md:text-lg leading-[1.5] max-w-[34ch]">
                            {lang.header.body}
                        </p>
                    </div>
                    {locale === 'cs' && (
                        <div className="flex flex-col items-start">
                            <Button href="/register-summer" className="mb-4">
                                {lang.header.buttonSummer}
                            </Button>

                            <Button href="/register">
                                {lang.header.buttonLongTerm}
                            </Button>
                        </div>
                    )}
                    {locale !== 'cs' && locale !== 'en' && (
                        <div className="flex flex-col items-start">
                            <Button href="/register">
                                {lang.header.button}
                            </Button>
                        </div>
                    )}
                </div>
            </Hero>

            <SectionFeatures/>

            <SectionTiles/>

            <SectionIconsWithLabels/>

            <SectionVideo>
                <header className="accent-purple-800">
                    <Title>
                        {lang.video.title.localize()}
                    </Title>
                </header>
                <p className="max-w-[34ch] leading-[1.8]">
                    {lang.video.body}
                </p>
            </SectionVideo>

            <SectionTestimonials/>

            <SectionInstagram/>
        </MainLayout>
    )
}
