import Link from "next/link";

const buttonClasses = 'relative inline-flex justify-center bg-red-800 rounded-[40px] text-white uppercase text-center font-semibold py-[1.45rem]  px-10 overflow-hidden group transition-all duration-500 will-change-transform transform';
const glowClasses = 'absolute bottom-0 left-20 w-80 h-44 bg-gradient-radial from-orange-600 md:from-orange-600/70 to-orange-600/0 rounded-[50%] pointer-events-none group-hover:-translate-x-2/3 group-hover:translate-y-2/3 transition-all ease-in-out duration-500 will-change-transform transform';

export default function Button({children, href, className = '', disabled = false, ...rest}) {

    if (href) {
        return (
            <Link href={href}>
                <a className={`${buttonClasses} ${className}`} {...rest}>
                    <span className={glowClasses}/>
                    <div className="relative">
                        {children}
                    </div>
                </a>
            </Link>
        )
    }

    return (
        <button className={`${buttonClasses} ${disabled ? 'opacity-50' : ''} ${className}`} disabled={disabled} {...rest}>
            <span className={glowClasses}/>
            <div className="relative">
                {children}
            </div>
        </button>
    )
}