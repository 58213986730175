import Link from "next/link";
import Image from "next/image";

export default function Tile({icon, title, body, imageMobile, imageDesktop, theme, href, className = ''}) {
    let themeClasses;

    switch (theme) {
        case 'purple':
            themeClasses = 'accent-purple-800';
            break;
        case 'orange':
            themeClasses = 'accent-orange-800';
            break;
        case 'lilac':
            themeClasses = 'accent-lilac-800';
            break;
        case 'pink':
            themeClasses = 'accent-pink-800';
            break;
        case 'green':
            themeClasses = 'accent-green-800';
            break;
        case 'blue':
            themeClasses = 'accent-blue-800';
            break;
        default:
            break;
    }

    return (
        <div
            className={`relative w-full rounded-[45px] min-h-[220px] md:min-h-[275px] overflow-hidden mouse-hover:-translate-y-1 mouse-hover:-translate-x-1 shadow-none mouse-hover:shadow-xl will-change-transform transition-all duration-200 ${themeClasses} ${className}`}>
            <div className="block md:hidden absolute top-0 left-0 w-full h-full">
                <Image
                    src={imageMobile}
                    lazyBoundary="700px"
                    objectFit="cover"
                    objectPosition="top"
                    layout="fill"
                    alt=""
                />
            </div>

            <div className="hidden md:block absolute top-0 left-0 w-full h-full">
                <Image
                    src={imageDesktop}
                    lazyBoundary="700px"
                    priority
                    objectFit="cover"
                    layout="fill"
                    alt=""
                />
            </div>

            <div className="absolute top-0 left-0 w-full h-full bg-accent-color opacity-50 mix-blend-color"/>
            <Link href={href}>
                <a className={`absolute top-0 left-0 w-full h-full flex flex-col gap-4 md:gap-6 py-7 px-6 md:p-10 text-white bg-gradient-to-r from-accent-color to-accent-color/50`}>
                    {icon && (
                        <p className="text-3xl md:text-4xl">
                            {icon}
                        </p>
                    )}
                    <div className="flex-1 flex flex-col gap-1.5 md:gap-3">
                        <header>
                            <h2 className="text-0.5xl md:text-2xl font-semibold">{title}</h2>
                        </header>
                        <p className="md:text-lg leading-[1.4] max-w-[23ch] md:max-w-[32ch]" dangerouslySetInnerHTML={{__html: body}}/>

                    </div>
                </a>
            </Link>
        </div>
    )
}