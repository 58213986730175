import Container from "../Container";
import Tile from "../Tile";
import imageJobMobile from "../../public/img/tiles/job_mobile.jpg";
import imageJobDesktop from "../../public/img/tiles/job_desktop.jpg";
import imageInternMobile from "../../public/img/tiles/intern_mobile.jpg";
import imageInternDesktop from "../../public/img/tiles/intern_desktop.jpg";
import imageJobOffersMobile from "../../public/img/tiles/job_offers_mobile.jpg";
import imageJobOffersDesktop from "../../public/img/tiles/job_offers_desktop.jpg";
import imageAboutMobile from "../../public/img/tiles/about_mobile.jpg";
import imageAboutDesktop from "../../public/img/tiles/about_desktop.jpg";
import imageProcessMobile from "../../public/img/tiles/process_mobile.jpg";
import imageProcessDesktop from "../../public/img/tiles/process_desktop.jpg";
import imageFaqMobile from "../../public/img/tiles/faq_mobile.jpg";
import imageFaqDesktop from "../../public/img/tiles/faq_desktop.jpg";
import {useRouter} from "next/router";
import localized from "../../locale/locales";

const tiles = [
    {
        icon: '🇳🇱',
        theme: 'purple',
        imageMobile: imageJobMobile,
        imageDesktop: imageJobDesktop,
        href: '/programmes/jobs-in-the-netherlands',
    },
    {
        icon: '💼',
        theme: 'orange',
        imageMobile: imageInternMobile,
        imageDesktop: imageInternDesktop,
        href: '/programmes/part-time-job-in-amsterdam',
    },
    {
        icon: '🛩',
        theme: 'lilac',
        imageMobile: imageJobOffersMobile,
        imageDesktop: imageJobOffersDesktop,
        href: '/job-offers',
    },
    {
        icon: '📝',
        theme: 'pink',
        imageMobile: imageAboutMobile,
        imageDesktop: imageAboutDesktop,
        href: '/about-us',
    },
    {
        icon: '✅',
        theme: 'green',
        imageMobile: imageProcessMobile,
        imageDesktop: imageProcessDesktop,
        href: '/how-it-works',
    },

    {
        icon: '❓',
        theme: 'blue',
        imageMobile: imageFaqMobile,
        imageDesktop: imageFaqDesktop,
        href: '/faq',
    },
];

export default function SectionTiles() {
    const {locale} = useRouter();
    const {sections: {tiles: lang}} = localized[locale];

    return (
        <Container className="relative">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8">
                {tiles.map((tile, key) => {
                    return (
                        <Tile {...tile}
                              {...lang.items[key]}
                              key={`Tile: ${key}`}
                        />
                    )
                })}
            </div>
        </Container>
    )
}