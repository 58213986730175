import TestimonialsCarousel from "../TestimonialsCarousel";
import Container from "../Container";
import {useRouter} from "next/router";
import localized from "../../locale/locales";
import Title from "../Title";

export default function SectionTestimonials() {
    const {locale} = useRouter();
    const {sections: {testimonials: lang}} = localized[locale];

    return (
        <Container className="relative">
            <div className="flex flex-col md:flex-row items-center gap-8 md:gap-4">
                <div className="flex-1 max-w-[380px] flex flex-col gap-5 w-full">
                    <header className="accent-purple-800">
                        <Title>{lang.title.localize({emoji: '🤙'})}</Title>
                    </header>
                </div>
                <div className="flex-[2] w-full">
                    <TestimonialsCarousel items={lang.items}/>
                </div>
            </div>
        </Container>
    )
}