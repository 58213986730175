import FeatureItem from "./FeatureItem";

export default function Features({items}) {
    return (
        <section
            className="flex items-start justify-between w-full max-w-[1046px] mx-auto overflow-auto gap-10 pt-1.5 pb-3 lg:pb-0 px-6">
            {items.map((item, key) => {
                return (
                    <FeatureItem {...item}
                                 color={key % 2 === 0 ? 'purple' : 'orange'}
                                 key={`FeatureItem: ${key}`}
                    />
                )
            })}
        </section>
    )
}