import Features from "../Features";
import Container from "../Container";
import {useRouter} from "next/router";
import localized from "../../locale/locales";

export default function SectionFeatures() {
    const {locale} = useRouter();
    const {sections: {features: lang}} = localized[locale];

    return (
        <Container className="py-20 md:pt-32 md:pb-36 -ml-5 -mr-5 w-[calc(100%+2.5rem)] md:mx-auto md:w-full">
            <Features items={lang.items}/>
        </Container>
    )
}