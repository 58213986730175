import Container from "../Container";
import Image from "next/image";

import imageSocial1 from "../../public/img/social_1.jpg";
import imageInstagram from "../../public/img/instagram.png";
import imageSocial2 from "../../public/img/social_2.jpg";
import {useEffect, useRef} from "react";
import {useRouter} from "next/router";
import localized from "../../locale/locales";

const moveImages = (image1, image2) => {
    if (window && image1 && image2) {
        const scrollPosition = window.scrollY;
        const image1Multiplier = window.innerWidth >= 768 ? -0.08 : 0;
        const image2Multiplier = window.innerWidth >= 768 ? 0.08 : 0;
        image1.style.transform = `translateY(${scrollPosition * image1Multiplier}px)`;
        image2.style.transform = `translateY(${scrollPosition * image2Multiplier}px)`;
    }
};

export default function SectionInstagram() {
    const {locale} = useRouter();
    const {sections: {instagram: lang}} = localized[locale];

    const image1Ref = useRef();
    const image2Ref = useRef();

    useEffect(() => {
        const handleScroll = () => {
            moveImages(image1Ref.current, image2Ref.current);
        };

        if (image1Ref.current) {
            window.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', handleScroll);
            handleScroll();
        }

        return () => {
            window.removeEventListener('resize', handleScroll);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <Container className="relative">
            <div
                className="flex flex-col md:flex-row gap-8 justify-center items-center py-20 md:pt-36 md:pb-52 will-change-transform">
                <div className="w-full md:flex-1 max-w-[300px] relative -left-24 md:left-auto md:top-48"
                     ref={image1Ref}>
                    <a href={`https://www.instagram.com/${lang.handle.substring(1)}/`}
                       target="_blank"
                       rel="nofollow noreferrer"
                       className="block w-full aspect-square relative transform transition-all duration-300 mouse-hover:-translate-y-2 mouse-hover:shadow-lg rounded-[45px]">
                        <Image src={imageSocial1}
                               lazyBoundary="700px"
                               className="rounded-[45px]"
                               layout="fill"
                               objectFit="cover"
                               alt=""
                        />
                    </a>
                </div>

                <div className="flex-1 max-w-[300px]">
                    <a href={`https://www.instagram.com/${lang.handle.substring(1)}/`}
                       target="_blank"
                       rel="nofollow noreferrer"
                       className="flex flex-col items-center gap-3"
                    >
                        <div className="w-14 md:w-24">
                            <Image src={imageInstagram} lazyBoundary="700px" alt=""/>
                        </div>
                        <div
                            className="inline-flex flex-col items-center text-lg md:text-xl font-bold leading-[1.4] md:leading-[1.5] text-center">
                            {lang.cta}
                            <span className="text-gradient">{lang.handle}</span>
                        </div>
                    </a>
                </div>

                <div
                    className="w-full md:flex-1 max-w-[300px] relative -right-24 md:right-auto md:-top-48 will-change-transform"
                    ref={image2Ref}>
                    <a href={`https://www.instagram.com/${lang.handle.substring(1)}/`}
                       target="_blank"
                       rel="nofollow noreferrer"
                       className="block w-full aspect-square relative transform transition-all duration-300 mouse-hover:-translate-y-2 mouse-hover:shadow-lg rounded-[45px]">
                        <Image src={imageSocial2}
                               lazyBoundary="700px"
                               className="rounded-[45px]"
                               layout="fill"
                               alt=""
                               objectFit="cover"
                        />
                    </a>
                </div>
            </div>
        </Container>
    )
}