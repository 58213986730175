export default function FeatureItem({title, body, color}) {
    let colorClasses;

    switch (color) {
        case 'orange':
            colorClasses = 'text-orange-800';
            break;
        case 'purple':
        default:
            colorClasses = 'text-purple-800';
            break;
    }

    return (
        <div className="flex-1 relative w-full max-w-[215px] min-h-[9rem] flex flex-col gap-4 px-6">
            <div className="relative text-5xl font-semibold leading-[0.6]">
                <span className="absolute left-0 top-0 2-left-6 w-36 h-36 rounded-full bg-white/50"/>
                <strong className={`relative whitespace-nowrap ${colorClasses}`}>{title}</strong>
            </div>
            <p className="relative mx-auto max-w-[15ch]">
                {body}
            </p>
        </div>
    )
}