import {useState} from "react";
import Image from "next/image";
import Popup from "./Popup";
import IconButton from "./IconButton";

import imageKnedla from '../public/img/testimonials/knedla.jpg';
import imageChovancova from '../public/img/testimonials/chovancova.jpg';
import imageSkalka from '../public/img/testimonials/skalka.jpg';
import imageSpicka from '../public/img/testimonials/spicka.jpg';
import imageGlobinovsky from '../public/img/testimonials/globinovsky.jpg';
import imageKovarik from '../public/img/testimonials/kovarik.jpg';


const images = [
    imageKnedla,
    imageChovancova,
    imageSkalka,
    imageSpicka,
    imageGlobinovsky,
    imageKovarik,
];

export default function TestimonialsCarousel({items}) {
    const [activeSlide, setActiveSlide] = useState(0);
    const [activePopup, setActivePopup] = useState(false);

    const testimonials = images.map((image, key) => {
        return {
            image,
            ...items[key]
        }
    })

    const handleClickNext = () => {
        testimonials.length > activeSlide + 1 ? setActiveSlide(activeSlide + 1) : setActiveSlide(0);
    }

    const handleClickPrevious = () => {
        activeSlide - 1 >= 0 ? setActiveSlide(activeSlide - 1) : setActiveSlide(testimonials.length - 1);
    }

    const handleClickReadMore = () => {
        setActivePopup(true);
    }

    const handleClickClose = () => {
        setActivePopup(false);
    }

    return (
        <>
            <Popup active={activePopup} onClose={handleClickClose}
                   className="max-w-[560px] bg-white bg-gradient-to-tr from-accent-color to-accent-color/50 max-h-[90vh]"
            >
                {testimonials.map(({name, body}, key) => {
                    return (
                        <article key={`Popup Testimonial: ${key}`}
                                 className={`flex-col gap-5 text-white overflow-auto h-full max-h-[calc(90vh-160px)] ${activeSlide === key ? 'flex' : 'hidden'}`}>
                            <header>
                                <h3 className="text-2xl font-semibold leading-[1.6]">
                                    {name}
                                </h3>
                            </header>
                            <p className="w-full leading-[1.6] w-full" dangerouslySetInnerHTML={{__html: body}}/>
                        </article>
                    )
                })}

                <div className="flex gap-8 py-4 justify-center">
                    <IconButton icon="arrow-left" onClick={handleClickPrevious}/>
                    <IconButton icon="arrow-right" onClick={handleClickNext}/>
                </div>
            </Popup>

            <section
                className="bg-white rounded-[45px] w-full flex flex-col md:flex-row md:items-stretch md:gap-9 relative accent-purple-800">
                <div
                    className="w-[calc(100%+2.5rem)] md:w-72 shrink-0 relative z-10 relative md:-top-9 text-0 pt-[65%] md:pt-0 -mx-5 md:mx-0">
                    {testimonials.map(({image}, key) => {
                        return (
                            <div
                                className={`absolute top-0 left-0 overflow-hidden w-full aspect-[75/49] md:aspect-square rounded-[30px] transform will-change-transform transition-all duration-500 ${activeSlide === key ? 'opacity-100 transform-none pointer-events-auto' : 'opacity-0 scale-[0.78] pointer-events-none'}`}
                                key={`Testimonial Image: ${key}`}>
                                <Image lazyBoundary="700px"
                                       src={image}
                                       layout="fill"
                                       objectFit="cover"
                                       className="rounded-[30px]"
                                       alt=""
                                />
                            </div>
                        )
                    })}
                </div>

                <div
                    className="absolute bg-gradient-to-tr from-accent-color to-accent-color/30 top-0 right-0 w-full md:w-[85%] h-full rounded-[45px]"/>

                <div
                    className="relative z-20 w-full pt-14 md:pt-10 pb-8 px-6 md:pl-0 md:pr-10 text-white flex flex-col gap-4">

                    <div className="relative min-h-[214px] w-full">
                        {testimonials.map(({name, body}, key) => {
                            return (
                                <div key={`Testimonial: ${key}`}
                                     className={`absolute top-0 left-0 w-full h-full flex flex-col gap-2 flex-start transition-all duration-500 will-change-transform transform ${activeSlide === key ? 'opacity-100 transform-none pointer-events-auto' : 'opacity-0 translate-y-8 pointer-events-none'}`}>
                                    <header>
                                        <h3 className="text-1.5xl md:text-2xl font-semibold leading-[1.6]">
                                            {name}
                                        </h3>
                                    </header>
                                    <p className="w-full leading-[1.6] max-w-[36ch] h-[132px] line-clamp-5">
                                        {body.substring(0, 230)}
                                    </p>
                                </div>
                            )
                        })}
                    </div>

                    <div className="flex justify-center md:justify-start gap-8">
                        <IconButton icon="arrow-left" onClick={handleClickPrevious}/>
                        <IconButton icon="plus" onClick={handleClickReadMore}/>
                        <IconButton icon="arrow-right" onClick={handleClickNext}/>
                    </div>
                </div>
            </section>
        </>
    )
}