import IconsWithLabels from "../IconsWithLabels";
import Container from "../Container";
import Title from "../Title";
import {useRouter} from "next/router";
import localized from "../../locale/locales";

const icons = [
    '💰',
    '🤩',
    '🎓',
    '🤝',
    '🏠',
    '💃',
];

export default function SectionIconsWithLabels() {
    const {locale} = useRouter();
    const {sections: {iconsWithLabels: lang}} = localized[locale];

    const items = icons.map((icon, key) => {
        return {icon, label: lang.labels[key]}
    });

    return (
        <Container className="relative">
            <div className="pt-24 flex flex-col gap-12 md:gap-20 accent-purple-800">
                <header className="text-center">
                    <Title>{lang.title.localize()}</Title>
                </header>
                <div className="w-full">
                    <IconsWithLabels items={items}/>
                </div>
            </div>
        </Container>
    )
}